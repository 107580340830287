<template>
    <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='false'
    width="35%" :title="`${isEdit ? '编辑' : '添加'}绑定`" :visible.sync="isShow">
    <!-- <select-map :localChange='onCallbackMarker' ref='map'></select-map> -->
    <div class="all-input">
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择人员：</div>
        <div style="width: 50%;!important" class="map" @click="selectPe">{{nickname}}</div>

      </div>
      <div style="width: 100%!important;" class="item-input">
        <div class="label"><span class="red">*</span>选择设备：</div>
        <el-select
          filterable
          style="width: 50%;!important"
          v-model="formData.u_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in uArr"
            :key="item.id"
            :label="item.unit_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <select-table ref="addP" :choice='onCallbackPe'></select-table>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getHBSelect, addHand, updHand } from '@/api/bind'
import selectTable from '@/components/selectTable'

export default {
  name: 'addHand',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    selectTable,
  },
  data () {
    return {
      isEdit: false,
      isShow: true,
      formData: {
        bind_id: '',
        u_id: '',
      },
      uArr: [], // 设备下拉
      nickname: '',
    }
  },
  created() {
    this._loadSelect()
    if (this.data.id) {
      this.isEdit = true
      this.nickname = this.data.nickname
      const { type, d_id: did, p_id: pid, bind_id: bid, u_id: uid, id } = this.data
      this.formData = { type: String(type), d_id: did, p_id: pid, bind_id: bid, u_id: uid, id }
      if (String(type) === '0') this.id = pid
      else if (String(type) === '2') this.id = did
    }
  },
  methods: {
    _loadSelect: function() {
      getHBSelect().then(res => {
        const { result } = res
        this.uArr = result.unit
      })
    },

    selectPe: function() {
      this.$refs.addP.open()
    },
    onCallbackPe: function(res) {
      this.formData.bind_id = res.id
      this.nickname = res.nickname
    },

    submit: function() {
      if (!this.formData.bind_id) {
        this.$toast('请选择 选择人员')
        return false
      } else if (!this.formData.u_id) {
        this.$toast('请选择 选择设备')
        return false
      }

      this.isEdit ? this.edit() : this.save()
    },
    save: function() {
      addHand(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    edit: function() {
      updHand(this.formData).then(res => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.$emit('close', 'success')
        }
      })
    },
    close: function() {
      this.isShow = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
